//
// VARIABLES
//

// Colors

:root {
  --text-color-main: #333;
  --text-color-links: #4183c4;
  --text-color-selection: #000;
  --text-color-headlines: #222;
  --text-color-quote: #666;
  --border-color-main: #eee;
  --background-color-main: #fff;
}

@media (prefers-color-scheme: dark)  {
  :root {
    --text-color-main: #cccccc;
    --text-color-links: #be7c3b;
    --text-color-selection: #ffffff;
    --text-color-headlines: #dddddd;
    --text-color-quote: #999999;

    --border-color-main: #111111;
    --background-color-main: #000000;
  }
}

// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$georgia: Georgia, serif;

// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 640px) {
    @content;
  }
}
