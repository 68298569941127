:root {
  --background-color-code-block: #eee;
  --text-color-code-plain-text: var(--text-color-main);

  --syntax-hightlight-color-comment: #586e75; /* Comment */
  --syntax-hightlight-color-error: #93a1a1; /* Error */
  --syntax-hightlight-color-generic: #93a1a1; /* Generic */
  --syntax-hightlight-color-keyword: #859900; /* Keyword */
  --syntax-hightlight-color-literal: #93a1a1; /* Literal */
  --syntax-hightlight-color-name: #93a1a1; /* Name */
  --syntax-hightlight-color-operator: #859900; /* Operator */
  --syntax-hightlight-color-other: #cb4b16; /* Other */
  --syntax-hightlight-color-punctuation: #93a1a1; /* Punctuation */
  --syntax-hightlight-color-comment-multiline: #586e75; /* Comment.Multiline */
  --syntax-hightlight-color-comment-preproc: #859900; /* Comment.Preproc */
  --syntax-hightlight-color-comment-single: #586e75; /* Comment.Single */
  --syntax-hightlight-color-comment-special: #859900; /* Comment.Special */
  --syntax-hightlight-color-generic-deleted: #2aa198; /* Generic.Deleted */
  --syntax-hightlight-color-generic-emph: #93a1a1; /* Generic.Emph */
  --syntax-hightlight-color-generic-error: #dc322f; /* Generic.Error */
  --syntax-hightlight-color-generic-heading: #cb4b16; /* Generic.Heading */
  --syntax-hightlight-color-generic-inserted: #859900; /* Generic.Inserted */
  --syntax-hightlight-color-generic-output: #93a1a1; /* Generic.Output */
  --syntax-hightlight-color-generic-prompt: #93a1a1; /* Generic.Prompt */
  --syntax-hightlight-color-generic-strong: #93a1a1; /* Generic.Strong */
  --syntax-hightlight-color-generic-subheading: #cb4b16; /* Generic.Subheading */
  --syntax-hightlight-color-generic-traceback: #93a1a1; /* Generic.Traceback */
  --syntax-hightlight-color-keyword-constant: #cb4b16; /* Keyword.Constant */
  --syntax-hightlight-color-keyword-declaration: #268bd2; /* Keyword.Declaration */
  --syntax-hightlight-color-keyword-namespace: #859900; /* Keyword.Namespace */
  --syntax-hightlight-color-keyword-pseudo: #859900; /* Keyword.Pseudo */
  --syntax-hightlight-color-keyword-reserved: #268bd2; /* Keyword.Reserved */
  --syntax-hightlight-color-keyword-type: #dc322f; /* Keyword.Type */
  --syntax-hightlight-color-literal-date: #93a1a1; /* Literal.Date */
  --syntax-hightlight-color-literal-number: #2aa198; /* Literal.Number */
  --syntax-hightlight-color-literal-string: #2aa198; /* Literal.String */
  --syntax-hightlight-color-name-attribute: #93a1a1; /* Name.Attribute */
  --syntax-hightlight-color-name-builtin: #b58900; /* Name.Builtin */
  --syntax-hightlight-color-name-class: #268bd2; /* Name.Class */
  --syntax-hightlight-color-name-constant: #cb4b16; /* Name.Constant */
  --syntax-hightlight-color-name-decorator: #268bd2; /* Name.Decorator */
  --syntax-hightlight-color-name-entity: #cb4b16; /* Name.Entity */
  --syntax-hightlight-color-name-exception: #cb4b16; /* Name.Exception */
  --syntax-hightlight-color-name-function: #268bd2; /* Name.Function */
  --syntax-hightlight-color-name-label: #93a1a1; /* Name.Label */
  --syntax-hightlight-color-name-namespace: #93a1a1; /* Name.Namespace */
  --syntax-hightlight-color-name-other: #555555; /* Name.Other */
  --syntax-hightlight-color-name-property: #93a1a1; /* Name.Property */
  --syntax-hightlight-color-name-tag: #268bd2; /* Name.Tag */
  --syntax-hightlight-color-name-variable: #268bd2; /* Name.Variable */
  --syntax-hightlight-color-operator-word: #859900; /* Operator.Word */
  --syntax-hightlight-color-text-whitespace: #93a1a1; /* Text.Whitespace */
  --syntax-hightlight-color-literal-number-float: #2aa198; /* Literal.Number.Float */
  --syntax-hightlight-color-literal-number-hex: #2aa198; /* Literal.Number.Hex */
  --syntax-hightlight-color-literal-number-integer: #2aa198; /* Literal.Number.Integer */
  --syntax-hightlight-color-literal-number-oct: #2aa198; /* Literal.Number.Oct */
  --syntax-hightlight-color-literal-string-backtick: #586e75; /* Literal.String.Backtick */
  --syntax-hightlight-color-literal-string-char: #2aa198; /* Literal.String.Char */
  --syntax-hightlight-color-literal-string-doc: #93a1a1; /* Literal.String.Doc */
  --syntax-hightlight-color-literal-string-double: #2aa198; /* Literal.String.Double */
  --syntax-hightlight-color-literal-string-escape: #cb4b16; /* Literal.String.Escape */
  --syntax-hightlight-color-literal-string-heredoc: #93a1a1; /* Literal.String.Heredoc */
  --syntax-hightlight-color-literal-string-interpol: #2aa198; /* Literal.String.Interpol */
  --syntax-hightlight-color-literal-string-other: #2aa198; /* Literal.String.Other */
  --syntax-hightlight-color-literal-string-regex: #dc322f; /* Literal.String.Regex */
  --syntax-hightlight-color-literal-string-single: #2aa198; /* Literal.String.Single */
  --syntax-hightlight-color-literal-string-symbol: #2aa198; /* Literal.String.Symbol */
  --syntax-hightlight-color-name-builtin-pseudo: #268bd2; /* Name.Builtin.Pseudo */
  --syntax-hightlight-color-name-variable-class: #268bd2; /* Name.Variable.Class */
  --syntax-hightlight-color-name-variable-global: #268bd2; /* Name.Variable.Global */
  --syntax-hightlight-color-name-variable-instance: #268bd2; /* Name.Variable.Instance */
  --syntax-hightlight-color-literal-number-integer-long: #2aa198; /* Literal.Number.Integer.Long */
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color-code-block: #242424;
    --text-color-code-plain-text: #ae3937;// var(--text-color-links);
    // TOOD/impl: uncomment and update colors if required
    // --syntax-hightlight-color-comment: #a7918a; /* Comment */
    // --syntax-hightlight-color-error: #6c5e5e; /* Error */
    // --syntax-hightlight-color-generic: #6c5e5e; /* Generic */
    // --syntax-hightlight-color-keyword: #7a66ff; /* Keyword */
    // --syntax-hightlight-color-literal: #6c5e5e; /* Literal */
    // --syntax-hightlight-color-name: #6c5e5e; /* Name */
    // --syntax-hightlight-color-operator: #7a66ff; /* Operator */
    // --syntax-hightlight-color-other: #34b4e9; /* Other */
    // --syntax-hightlight-color-punctuation: #6c5e5e; /* Punctuation */
    // --syntax-hightlight-color-comment-multiline: #a7918a; /* Comment.Multiline */
    // --syntax-hightlight-color-comment-preproc: #7a66ff; /* Comment.Preproc */
    // --syntax-hightlight-color-comment-single: #a7918a; /* Comment.Single */
    // --syntax-hightlight-color-comment-special: #7a66ff; /* Comment.Special */
    // --syntax-hightlight-color-generic-deleted: #d55e67; /* Generic.Deleted */
    // --syntax-hightlight-color-generic-emph: #6c5e5e; /* Generic.Emph */
    // --syntax-hightlight-color-generic-error: #23cdd0; /* Generic.Error */
    // --syntax-hightlight-color-generic-heading: #34b4e9; /* Generic.Heading */
    // --syntax-hightlight-color-generic-inserted: #7a66ff; /* Generic.Inserted */
    // --syntax-hightlight-color-generic-output: #6c5e5e; /* Generic.Output */
    // --syntax-hightlight-color-generic-prompt: #6c5e5e; /* Generic.Prompt */
    // --syntax-hightlight-color-generic-strong: #6c5e5e; /* Generic.Strong */
    // --syntax-hightlight-color-generic-subheading: #34b4e9; /* Generic.Subheading */
    // --syntax-hightlight-color-generic-traceback: #6c5e5e; /* Generic.Traceback */
    // --syntax-hightlight-color-keyword-constant: #34b4e9; /* Keyword.Constant */
    // --syntax-hightlight-color-keyword-declaration: #d9742d; /* Keyword.Declaration */
    // --syntax-hightlight-color-keyword-namespace: #7a66ff; /* Keyword.Namespace */
    // --syntax-hightlight-color-keyword-pseudo: #7a66ff; /* Keyword.Pseudo */
    // --syntax-hightlight-color-keyword-reserved: #d9742d; /* Keyword.Reserved */
    // --syntax-hightlight-color-keyword-type: #23cdd0; /* Keyword.Type */
    // --syntax-hightlight-color-literal-date: #6c5e5e; /* Literal.Date */
    // --syntax-hightlight-color-literal-number: #d55e67; /* Literal.Number */
    // --syntax-hightlight-color-literal-string: #d55e67; /* Literal.String */
    // --syntax-hightlight-color-name-attribute: #6c5e5e; /* Name.Attribute */
    // --syntax-hightlight-color-name-builtin: #4a76ff; /* Name.Builtin */
    // --syntax-hightlight-color-name-class: #d9742d; /* Name.Class */
    // --syntax-hightlight-color-name-constant: #34b4e9; /* Name.Constant */
    // --syntax-hightlight-color-name-decorator: #d9742d; /* Name.Decorator */
    // --syntax-hightlight-color-name-entity: #34b4e9; /* Name.Entity */
    // --syntax-hightlight-color-name-exception: #34b4e9; /* Name.Exception */
    // --syntax-hightlight-color-name-function: #d9742d; /* Name.Function */
    // --syntax-hightlight-color-name-label: #6c5e5e; /* Name.Label */
    // --syntax-hightlight-color-name-namespace: #6c5e5e; /* Name.Namespace */
    // --syntax-hightlight-color-name-other: #aaaaaa; /* Name.Other */
    // --syntax-hightlight-color-name-property: #6c5e5e; /* Name.Property */
    // --syntax-hightlight-color-name-tag: #d9742d; /* Name.Tag */
    // --syntax-hightlight-color-name-variable: #d9742d; /* Name.Variable */
    // --syntax-hightlight-color-operator-word: #7a66ff; /* Operator.Word */
    // --syntax-hightlight-color-text-whitespace: #6c5e5e; /* Text.Whitespace */
    // --syntax-hightlight-color-literal-number-float: #d55e67; /* Literal.Number.Float */
    // --syntax-hightlight-color-literal-number-hex: #d55e67; /* Literal.Number.Hex */
    // --syntax-hightlight-color-literal-number-integer: #d55e67; /* Literal.Number.Integer */
    // --syntax-hightlight-color-literal-number-oct: #d55e67; /* Literal.Number.Oct */
    // --syntax-hightlight-color-literal-string-backtick: #a7918a; /* Literal.String.Backtick */
    // --syntax-hightlight-color-literal-string-char: #d55e67; /* Literal.String.Char */
    // --syntax-hightlight-color-literal-string-doc: #6c5e5e; /* Literal.String.Doc */
    // --syntax-hightlight-color-literal-string-double: #d55e67; /* Literal.String.Double */
    // --syntax-hightlight-color-literal-string-escape: #34b4e9; /* Literal.String.Escape */
    // --syntax-hightlight-color-literal-string-heredoc: #6c5e5e; /* Literal.String.Heredoc */
    // --syntax-hightlight-color-literal-string-interpol: #d55e67; /* Literal.String.Interpol */
    // --syntax-hightlight-color-literal-string-other: #d55e67; /* Literal.String.Other */
    // --syntax-hightlight-color-literal-string-regex: #23cdd0; /* Literal.String.Regex */
    // --syntax-hightlight-color-literal-string-single: #d55e67; /* Literal.String.Single */
    // --syntax-hightlight-color-literal-string-symbol: #d55e67; /* Literal.String.Symbol */
    // --syntax-hightlight-color-name-builtin-pseudo: #d9742d; /* Name.Builtin.Pseudo */
    // --syntax-hightlight-color-name-variable-class: #d9742d; /* Name.Variable.Class */
    // --syntax-hightlight-color-name-variable-global: #d9742d; /* Name.Variable.Global */
    // --syntax-hightlight-color-name-variable-instance: #d9742d; /* Name.Variable.Instance */
    // --syntax-hightlight-color-literal-number-integer-long: #d55e67; /* Literal.Number.Integer.Long */
  }
}

// NOTE/impl: https://github.com/barryclark/jekyll-now/issues/1526
pre {
  background-color: var(--background-color-code-block);
  padding: 7px 7px 7px 10px;
  border: 1px solid var(--background-color-code-block);
  -moz-box-shadow: 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.1);
  margin: 20px 0 20px 0;
  overflow: scroll;
}

code {
  font-family: "Bitstream Vera Sans Mono", "Courier", monospace;
  background-color: var(--background-color-code-block);

  &.language-plaintext {
    color: var(--text-color-code-plain-text);
  }
}

.highlight {
  .c {
    color: var(--syntax-hightlight-color-comment);
  } /* Comment */
  .err {
    color: var(--syntax-hightlight-color-error);
  } /* Error */
  .g {
    color: var(--syntax-hightlight-color-generic);
  } /* Generic */
  .k {
    color: var(--syntax-hightlight-color-keyword);
  } /* Keyword */
  .l {
    color: var(--syntax-hightlight-color-literal);
  } /* Literal */
  .n {
    color: var(--syntax-hightlight-color-name);
  } /* Name */
  .o {
    color: var(--syntax-hightlight-color-operator);
  } /* Operator */
  .x {
    color: var(--syntax-hightlight-color-other);
  } /* Other */
  .p {
    color: var(--syntax-hightlight-color-punctuation);
  } /* Punctuation */
  .cm {
    color: var(--syntax-hightlight-color-comment-multiline);
  } /* Comment.Multiline */
  .cp {
    color: var(--syntax-hightlight-color-comment-preproc);
  } /* Comment.Preproc */
  .c1 {
    color: var(--syntax-hightlight-color-comment-single);
  } /* Comment.Single */
  .cs {
    color: var(--syntax-hightlight-color-comment-special);
  } /* Comment.Special */
  .gd {
    color: var(--syntax-hightlight-color-generic-deleted);
  } /* Generic.Deleted */
  .ge {
    color: var(--syntax-hightlight-color-generic-emph);
  } /* Generic.Emph */
  .gr {
    color: var(--syntax-hightlight-color-generic-error);
  } /* Generic.Error */
  .gh {
    color: var(--syntax-hightlight-color-generic-heading);
  } /* Generic.Heading */
  .gi {
    color: var(--syntax-hightlight-color-generic-inserted);
  } /* Generic.Inserted */
  .go {
    color: var(--syntax-hightlight-color-generic-output);
  } /* Generic.Output */
  .gp {
    color: var(--syntax-hightlight-color-generic-prompt);
  } /* Generic.Prompt */
  .gs {
    color: var(--syntax-hightlight-color-generic-strong);
  } /* Generic.Strong */
  .gu {
    color: var(--syntax-hightlight-color-generic-subheading);
  } /* Generic.Subheading */
  .gt {
    color: var(--syntax-hightlight-color-generic-traceback);
  } /* Generic.Traceback */
  .kc {
    color: var(--syntax-hightlight-color-keyword-constant);
  } /* Keyword.Constant */
  .kd {
    color: var(--syntax-hightlight-color-keyword-declaration);
  } /* Keyword.Declaration */
  .kn {
    color: var(--syntax-hightlight-color-keyword-namespace);
  } /* Keyword.Namespace */
  .kp {
    color: var(--syntax-hightlight-color-keyword-pseudo);
  } /* Keyword.Pseudo */
  .kr {
    color: var(--syntax-hightlight-color-keyword-reserved);
  } /* Keyword.Reserved */
  .kt {
    color: var(--syntax-hightlight-color-keyword-type);
  } /* Keyword.Type */
  .ld {
    color: var(--syntax-hightlight-color-literal-date);
  } /* Literal.Date */
  .m {
    color: var(--syntax-hightlight-color-literal-number);
  } /* Literal.Number */
  .s {
    color: var(--syntax-hightlight-color-literal-string);
  } /* Literal.String */
  .na {
    color: var(--syntax-hightlight-color-name-attribute);
  } /* Name.Attribute */
  .nb {
    color: var(--syntax-hightlight-color-name-builtin);
  } /* Name.Builtin */
  .nc {
    color: var(--syntax-hightlight-color-name-class);
  } /* Name.Class */
  .no {
    color: var(--syntax-hightlight-color-name-constant);
  } /* Name.Constant */
  .nd {
    color: var(--syntax-hightlight-color-name-decorator);
  } /* Name.Decorator */
  .ni {
    color: var(--syntax-hightlight-color-name-entity);
  } /* Name.Entity */
  .ne {
    color: var(--syntax-hightlight-color-name-exception);
  } /* Name.Exception */
  .nf {
    color: var(--syntax-hightlight-color-name-function);
  } /* Name.Function */
  .nl {
    color: var(--syntax-hightlight-color-name-label);
  } /* Name.Label */
  .nn {
    color: var(--syntax-hightlight-color-name-namespace);
  } /* Name.Namespace */
  .nx {
    color: var(--syntax-hightlight-color-name-other);
  } /* Name.Other */
  .py {
    color: var(--syntax-hightlight-color-name-property);
  } /* Name.Property */
  .nt {
    color: var(--syntax-hightlight-color-name-tag);
  } /* Name.Tag */
  .nv {
    color: var(--syntax-hightlight-color-name-variable);
  } /* Name.Variable */
  .ow {
    color: var(--syntax-hightlight-color-operator-word);
  } /* Operator.Word */
  .w {
    color: var(--syntax-hightlight-color-text-whitespace);
  } /* Text.Whitespace */
  .mf {
    color: var(--syntax-hightlight-color-literal-number-float);
  } /* Literal.Number.Float */
  .mh {
    color: var(--syntax-hightlight-color-literal-number-hex);
  } /* Literal.Number.Hex */
  .mi {
    color: var(--syntax-hightlight-color-literal-number-integer);
  } /* Literal.Number.Integer */
  .mo {
    color: var(--syntax-hightlight-color-literal-number-oct);
  } /* Literal.Number.Oct */
  .sb {
    color: var(--syntax-hightlight-color-literal-string-backtick);
  } /* Literal.String.Backtick */
  .sc {
    color: var(--syntax-hightlight-color-literal-string-char);
  } /* Literal.String.Char */
  .sd {
    color: var(--syntax-hightlight-color-literal-string-doc);
  } /* Literal.String.Doc */
  .s2 {
    color: var(--syntax-hightlight-color-literal-string-double);
  } /* Literal.String.Double */
  .se {
    color: var(--syntax-hightlight-color-literal-string-escape);
  } /* Literal.String.Escape */
  .sh {
    color: var(--syntax-hightlight-color-literal-string-heredoc);
  } /* Literal.String.Heredoc */
  .si {
    color: var(--syntax-hightlight-color-literal-string-interpol);
  } /* Literal.String.Interpol */
  .sx {
    color: var(--syntax-hightlight-color-literal-string-other);
  } /* Literal.String.Other */
  .sr {
    color: var(--syntax-hightlight-color-literal-string-regex);
  } /* Literal.String.Regex */
  .s1 {
    color: var(--syntax-hightlight-color-literal-string-single);
  } /* Literal.String.Single */
  .ss {
    color: var(--syntax-hightlight-color-literal-string-symbol);
  } /* Literal.String.Symbol */
  .bp {
    color: var(--syntax-hightlight-color-name-builtin-pseudo);
  } /* Name.Builtin.Pseudo */
  .vc {
    color: var(--syntax-hightlight-color-name-variable-class);
  } /* Name.Variable.Class */
  .vg {
    color: var(--syntax-hightlight-color-name-variable-global);
  } /* Name.Variable.Global */
  .vi {
    color: var(--syntax-hightlight-color-name-variable-instance);
  } /* Name.Variable.Instance */
  .il {
    color: var(--syntax-hightlight-color-literal-number-integer-long);
  } /* Literal.Number.Integer.Long */
}
