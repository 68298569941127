// MY CUSTOM styles

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

video {
  max-width: 100%;
  max-height: 600px;
}
