th {
  height: 30px;
  font-weight: bold;
}

th,
td {
  padding-right: 10px;
  padding-left: 10px;

  &:first-of-type {
    padding-left: 0;
  }
}
